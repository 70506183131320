<template>
  <div id="app">
      <h2>Current Conditions</h2>
      <p>{{ conditions }}</p>
      <div class="temp"><span v-html="wicon"></span>{{ currentTemp }}&deg;F</div>
      <p>Feels like {{ feelsLike }}</p>
      <p>Chance of rain is {{ precipPct }}%.</p>
      <p>Winds: {{ wind }}mph</p>
      <p>Visibility: {{ vis }} miles</p>
      <p class="small">Local observation time: {{ obsTime }}</p>
  </div>
</template>

<script>
import axios from 'axios'
import icons from './plugins/weather-icons'

export default {
  name: 'app',
  props: { geo: {type: String, default: '1.489706,124.84620' } },
  data: () => ({
      currentTemp: 0,
      feelsLike: 0,
      conditions: '',
      precipPct: 0,
      wicon: '',
      wind: 0,
      vis: 0,
      obsTime: ''
  }),
  created: function () {
      axios.get('https://us-central1-hotels-8fb5d.cloudfunctions.net/weather?geo=' + this.geo)
        .then(response => {
            // eslint-disable-next-line
          console.log(response.data)
          this.currentTemp = Math.round(response.data.currently.temperature)
          this.feelsLike = Math.round(response.data.currently.apparentTemperature)
          this.conditions = response.data.currently.summary
          // this.precipPct = response.data.currently.precipProbablility
          this.precipPct = Math.round(response.data.currently.precipProbability * 100)
          this.wicon = icons.icons[response.data.currently.icon]
          this.wind = response.data.currently.windSpeed
          this.vis = Math.round(response.data.currently.visibility)
          let obsDate = new Date(response.data.currently.time)
          //let obsDate = new Date()
          this.obsTime = obsDate.getHours() + ':' + obsDate.getMinutes()
            // eslint-disable-next-line
          // console.log(response.data.currently.precipProbability)
      })
  }
}
</script>

<style>
    svg { width: 48px; height: 48px; fill: #064273; margin-right: 12px; }
    .temp { font-size: 48px; color: #064273; font-weight: 700; }
    p { margin: 0; }
</style>
